.main-projects {
    @apply w-[60%] h-[100%] m-auto;
    margin-top: 5%;
}

.projects-text {
    font-family: 'Prata', serif;
    font-size: 50px;
    margin-bottom: 4%;
    padding-left: 10px;
}

.projects-title {
    @apply flex;
    font-family: 'Prata', serif;
    font-size: 30px;
    padding-bottom: 0.3rem;
}

.projects-description {
    font-family: 'Prata', serif;
    font-family: 'Source Code Pro', monospace;
    font-size: 14px;
    font-weight: 300;
    padding-left: 5px;
}

.projects-tools {
    font-family: 'Prata', serif;
    font-family: 'Source Code Pro', monospace;
    font-size: 14px;
    font-weight: 300;
    padding-top: 20px;
    float: right;
    font-style: italic;
}

.wrapper {
    @apply flex-1;
}

.project-container {
    padding-bottom: 50px;
}

hr {
    @apply border-black pl-[2.5] pb-2;
}

a {
    padding: 5px;
}

a:hover {
    background-color: antiquewhite;
    border-radius: 10px;
}

.wrapperNextBtn {
    padding-left: 95%;
}

.nextBtnDiv {
    font-size: 40px;
    font-family: 'Prata', serif;
    font-family: 'Source Code Pro', monospace;
    color: #e07a5f;
}

.nextBtnDiv:hover {
    text-decoration: underline;;
}